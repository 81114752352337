'use client'
import {useState} from "react";
import ResultRow, {ResultRowSkeleton} from "@/app/components/auctor/result/ResultRow";
import {IAppliedFilter, ITemplateConfig} from "@/app/models/auctor";
import dynamic from "next/dynamic";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'), {
    ssr: false,
});

export default function ResultList({templateConfig, appliedFilters, featuredResults, results, isPreview, isHidden}: {
    templateConfig: ITemplateConfig,
    appliedFilters: IAppliedFilter[],
    featuredResults: any[],
    results: any[],
    isPreview?: boolean,
    isHidden?: boolean
}) {
    const [showMore, setShowMore] = useState(false);
    const featuredResultsToShow = showMore ? featuredResults : featuredResults.slice(0, 10);
    const resultsToShow = showMore ? results : results.slice(0, 10);

    const atLeastOneColumnHasLabel = templateConfig?.column_configs?.some((column: any) => column.label);

    return (
        <div
            className={`flex-col items-center w-full ${isHidden ? 'hidden' : 'flex'}`}
        >
            <table className={'border-separate border-spacing-y-4 -translate-y-4 w-full'}>
                {atLeastOneColumnHasLabel && (
                    <thead className="bg-blue-200 rounded p-4 text-blue-800 font-semibold hidden md:table-row-group w-full">
                        <tr className="table-row-header w-full">
                            {templateConfig.column_configs.map((column: any, idx: number) => (
                                <th
                                    key={'column-' + idx}
                                    className={`px-2 py-8 w-${column.col_span}/12 border-y border-blue-300 border-r first:border-l
                                ${idx === 0 ? 'rounded-l-xl' : ''}
                                ${idx === templateConfig.column_configs.length - 1 ? 'rounded-r-xl' : ''}
                               `}
                                >
                                    {column.label}
                                </th>
                            ))}
                            <th className="absolute hidden"></th>
                        </tr>
                    </thead>
                )}

                <tbody>
                    {featuredResultsToShow?.map((resultRow: any, idx: number) => (
                        <ResultRow
                            isPreview={isPreview}
                            key={'result-' + idx}
                            templateConfig={templateConfig}
                            appliedFilters={appliedFilters}
                            resultRow={resultRow}
                            isFeatured={idx === 0}
                        />
                    ))}

                    {resultsToShow?.map((resultRow: any, idx: number) => (
                        <ResultRow
                            isPreview={isPreview}
                            key={'result-' + idx}
                            templateConfig={templateConfig}
                            appliedFilters={appliedFilters}
                            resultRow={resultRow}
                            isFeatured={featuredResults.length === 0 && idx === 0}
                        />
                    ))}
                </tbody>
            </table>

            {results.length > 10 && (
                <button
                    className="p-2 bg-blue-100 font-semibold border border-blue-300 rounded
                            hover:border-blue-400 hover:bg-blue-400 transition-all duration-300 text-blue-700"
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore && (
                        <>
                            <AppIcon type="duotone" iconClassName="fa-chevron-up" className="mr-2" />
                            Afficher moins
                        </>
                    )}
                    {!showMore && (
                        <>
                            <AppIcon type="duotone" iconClassName="fa-chevron-down" className="mr-2" />
                            Afficher plus d&apos;offres
                        </>
                    )}
                </button>
            )}
        </div>
    )
}

export function ResultsSkeleton({count}: {
    count: number
}) {
    return (
        <>
            <div className="flex flex-col items-center w-full gap-4">
                {Array.from(Array(count).keys()).map((idx: number) => (
                    <ResultRowSkeleton key={idx} withBanner={idx === 0} />
                ))}
            </div>
        </>
    )
}
