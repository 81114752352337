'use client'
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import React, {Suspense, useEffect, useState} from "react";
import {IBannerConfig, IColumnConfig, IAppliedFilter, ITemplateConfig} from "@/app/models/auctor";
import ResultBanner, {BannerSkeleton} from "@/app/components/auctor/result/ResultBanner";
import ResultColumn from "@/app/components/auctor/result/ResultColumn";

import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";

interface ResultRowProps {
    resultRow: any;
    templateConfig: ITemplateConfig;
    appliedFilters: IAppliedFilter[];
    isPreview?: boolean;
    isFeatured?: boolean;
}

export default function ResultRow({resultRow, templateConfig, appliedFilters, isFeatured = false, isPreview = false}: ResultRowProps) {
    const [enabledBanner, setEnabledBanner] = useState<IBannerConfig | null>(null);
    const [mobileWrappedColumn, setMobileWrappedColumn] = useState<IColumnConfig | null>(null)

    useEffect(() => {
        let bannerToDisplay = structuredClone(getEnabledBannerConfig(resultRow, templateConfig))

        if (isFeatured === true) {
            const featuredText = 'Notre recommandation'
            const featuredIcon = 'fa-medal'
            const featuredColor = '#5cb85c'

            if (!bannerToDisplay) {
                bannerToDisplay = {} as IBannerConfig
            }

            if (bannerToDisplay.content) {
                bannerToDisplay.content = `${featuredText} - ${bannerToDisplay.content}`
            } else {
                bannerToDisplay.content = featuredText
            }

            bannerToDisplay.fa_icon = featuredIcon
            bannerToDisplay.color = featuredColor
        }

        setEnabledBanner(bannerToDisplay as IBannerConfig)
    }, [resultRow, templateConfig, isFeatured])

    const getEnabledBannerConfig = (resultRow: any, templateConfig: any): IBannerConfig | null => {
        let enabledBannerConfig = null
        templateConfig.banner_configs.forEach((bannerConfigFromTemplate: any) => {
            if (!bannerConfigFromTemplate.is_custom_banner && resultRow[bannerConfigFromTemplate.banner_column_name]) {
                enabledBannerConfig = bannerConfigFromTemplate
                enabledBannerConfig.content = resultRow[bannerConfigFromTemplate.banner_column_name]
            }

            if (bannerConfigFromTemplate.is_custom_banner && bannerConfigFromTemplate.enable_condition) {
                try {
                    let expression = bannerConfigFromTemplate.enable_condition
                    if (!expression.includes('return')) {
                        expression = `return ${expression}`
                    }

                    const expressionFunction = new Function('data', expression)
                    const isEnabled = expressionFunction(resultRow)

                    if (isEnabled) {
                        enabledBannerConfig = bannerConfigFromTemplate
                    }
                } catch (error) {
                    console.error(`Error evaluating condition for banner`, error)
                    return null
                }
            }
        })

        return enabledBannerConfig;
    }

    const defaultBorderColor = '#cbddee'
    const enabledBannerBorderColor = `${enabledBanner?.color} ${defaultBorderColor} ${enabledBanner?.color} ${enabledBanner?.color}`

    return (
        <>
            <tr
                className={`relative w-full bg-white md:h-50
                        ${enabledBanner ? '-mt-2' : 'border-blue-300'}`
                }
            >
                {templateConfig?.price_expression && (
                    <td
                        className={`md:hidden w-1/3 items-center justify-center p-4 bg-blue-200 
                                    ${mobileWrappedColumn ? 'border-x border-t rounded-tl-xl' : 'rounded-l-xl border'}`
                        }
                        style={enabledBanner ? {borderColor: enabledBannerBorderColor} : {borderColor: defaultBorderColor}}
                    >
                        <div
                            className="flex flex-col items-center justify-center gap-1 text-center"
                            dangerouslySetInnerHTML={{
                                __html: evaluateExpressionWithFiltersAndRowData(
                                    templateConfig.price_expression,
                                    resultRow,
                                    appliedFilters
                                ) ?? '',
                            }}
                        />
                    </td>
                )}

                <td
                    className={`md:hidden border-r
                                ${mobileWrappedColumn ? 'rounded-tr-xl border-t' : 'rounded-r-xl border-y'}
                                ${templateConfig?.price_expression ? 'w-2/3' : 'rounded-l-xl border'}
                                ${enabledBanner ? 'pt-10' : ''}`
                    }
                    style={enabledBanner ? {borderColor: enabledBanner.color} : {borderColor: defaultBorderColor}}
                >
                    {templateConfig?.column_configs.map((column: any, i: number) => (
                        <MemoizedResultColumn
                            key={i}
                            isPreview={isPreview}
                            column={column}
                            appliedFilters={appliedFilters}
                            resultRow={resultRow}
                            templateConfig={templateConfig}
                            isWrappableOnMobile={column.wrapped_on_mobile}
                            setMobileWrappedColumn={setMobileWrappedColumn}
                        />
                    ))}
                </td>

                {templateConfig?.column_configs.map((column: any, i: number) => (
                    <td
                        key={i}
                        className={`border-y w-${column.col_span}/12 hidden md:table-cell
                                ${enabledBanner ? 'pt-10' : ''}
                                ${i === 0 ? 'rounded-l-xl border-l' : ''}
                                ${i === templateConfig.column_configs.length - 1 ? 'rounded-r-xl border-r' : ''}`
                        }
                        style={enabledBanner ? {borderColor: enabledBanner.color} : {borderColor: defaultBorderColor}}
                    >
                        <MemoizedResultColumn
                            isPreview={isPreview}
                            column={column}
                            appliedFilters={appliedFilters}
                            resultRow={resultRow}
                            templateConfig={templateConfig}
                            isWrappableOnMobile={column.wrapped_on_mobile}
                        />
                    </td>
                ))}

                <td
                    className={'absolute w-full rounded-t-xl left-0'}
                    style={{backgroundColor: enabledBanner?.color}}
                >
                    {enabledBanner &&
                        <Suspense fallback={<BannerSkeleton />}>
                            <ResultBanner
                                banner={enabledBanner}
                                resultRow={resultRow}
                            />
                        </Suspense>
                    }
                </td>
            </tr>

            {mobileWrappedColumn &&
                <tr>
                    <td
                        className="md:hidden w-full bg-white -translate-y-4 border-x border-b rounded-b-xl"
                        style={enabledBanner ? {borderColor: enabledBanner.color} : {borderColor: defaultBorderColor}}
                        colSpan={3}
                    >
                        <ResultColumn
                            isPreview={isPreview}
                            column={mobileWrappedColumn}
                            appliedFilters={appliedFilters}
                            resultRow={resultRow}
                            templateConfig={templateConfig}
                        />
                    </td>
                </tr>
            }
        </>
    );
}

export function ResultRowSkeleton({withBanner = false}) {
    return (
        <div className={'relative w-full'}>
            {withBanner && <BannerSkeleton />}
            <div
                className={`relative grid bg-blue-100 w-full rounded-xl z-10 border border-blue-300 
                md:h-50 md:grid-cols-4 overflow-hidden ${withBanner && 'mt-9'}`}
            >
                <Skeleton
                    circle
                    inline
                    height={100}
                    width={100}
                    containerClassName="flex-col items-center justify-center p-4 hidden md:flex"
                />

                <Skeleton
                    inline
                    height={80}
                    width={160}
                    borderRadius={'1rem'}
                    containerClassName="flex flex-col items-center justify-center p-4 md:hidden"
                />

                <Skeleton
                    inline
                    width={100}
                    height={20}
                    containerClassName="h-40 md:h-full flex flex-col items-center justify-center p-2 bg-blue-100"
                />

                <Skeleton
                    count={4}
                    height={15}
                    containerClassName="flex-col items-center justify-center p-4 hidden md:flex"
                />

                <Skeleton
                    inline
                    height={32}
                    width={120}
                    containerClassName="flex flex-col items-center justify-center px-4 pb-2 pt-4 md:hidden"
                />

                <Skeleton
                    inline
                    height={50}
                    containerClassName="flex flex-col items-center justify-center px-4 pt-2 pb-4 w-full md:w-40 mx-auto"
                />
            </div>
        </div>
    );
}

const MemoizedResultColumn = React.memo(ResultColumn);
