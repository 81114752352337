'use client'
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import {IBannerConfig} from "@/app/models/auctor";
import dynamic from "next/dynamic";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'), {
    ssr: false,
});

interface BannerProps {
    banner: IBannerConfig,
    resultRow: any,
}

export default function ResultBanner({banner}: BannerProps) {
    return (
        <span
            className="flex justify-start items-center gap-2
                px-4 py-2 w-full lg:w-auto text-white font-semibold
                -top-0 relative z-0"
            // style={{backgroundColor: banner.color}}
        >
            {banner.fa_icon &&
                <AppIcon iconClassName={`${banner.fa_icon}`} />
            }

            <span>{banner.content}</span>
        </span>
    );
}

export function BannerSkeleton() {
    return (<Skeleton
        inline
        containerClassName="flex justify-start items-center gap-2
                px-4 pt-2 pb-3 rounded-t-xl w-full lg:w-auto text-white font-semibold
                -top-0 absolute z-0 min-w-[300px] h-[38.5px] bg-blue-200"
    />)
}
